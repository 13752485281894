import { Avatar, Box, Container, Stack, Typography, styled } from '@mui/material';
import { theme } from '../../theme/Theme';
import InfoIcon from '../../assets/img/info-icon.png';
import QuotationFeeBreakdownPopup from './QuotationFeeBreakdownPopup';
import { initialSliderConfig } from '../../components/Sliders/constants';
import { useState } from 'react';
import { GetInstallmentLoanQuotationResponse, GetShortTermLoanQuotationResponse } from '../../clients/LoanClient';
import { formatMoney, formatMoneyWithoutRounding } from '../../utils/Helpers/SliderHelperFunctions';

const BreakdownItem = styled(Stack)({
  display: 'flex',
  borderBottom: `1px solid #E4E4E4`,
  padding: '1.6rem 1.6rem 0.8rem 1.6rem',
  transition: 'all 0.25s ease-in-out',
});

interface CreditLifeBreakdownProps {
  deathBenefitOptedIn: boolean;
  getQuotationResponse: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined;
  creditLifeSaving?: number;
}

const CreditLifeBreakdown: React.FunctionComponent<CreditLifeBreakdownProps> = ({
  deathBenefitOptedIn,
  getQuotationResponse,
  creditLifeSaving,
}: CreditLifeBreakdownProps) => {
  const [showQuotationBreakdownPopup, setShowQuotationBreakdownPopup] = useState<boolean>(false);

  const getInstalmentAmount = () => {
    const isInstallmentLoan = initialSliderConfig.installmentLoanConfig.productId === getQuotationResponse?.productId;
    // NOTE: We should always add deathBenefitPremium to installment amount
    // And deathBenefitContractValue to totalRepayable
    if (isInstallmentLoan) {
      return formatMoney(
        ((getQuotationResponse as GetInstallmentLoanQuotationResponse).installmentAmount ?? 0) +
        (getQuotationResponse?.deathBenefitPremium ?? 0)
      );
    }

    return formatMoney(
      (getQuotationResponse?.totalRepayable ?? 0) + (getQuotationResponse?.deathBenefitContractValue ?? 0)
    );
  };

  return (
    <Container disableGutters sx={{ marginBottom: { xs: '3.2rem', md: '4rem' } }}>
      {deathBenefitOptedIn && (
        <BreakdownItem
          flexDirection={'column'}
          alignItems={'space-between'}
          justifyContent={'flex-start'}
          sx={{ paddingY: '1.6rem', paddingX: { xs: '1.6rem', sm: '0' } }}
        >
          <Stack flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
              Death Benefit cover premium
            </Typography>
            <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
              {`R ${formatMoney(getQuotationResponse?.deathBenefitPremium ?? 0)}`}
            </Typography>
          </Stack>
        </BreakdownItem>
      )}

      <BreakdownItem
        flexDirection={'row'}
        alignItems="flex-start"
        justifyContent={'space-between'}
        sx={deathBenefitOptedIn ? { paddingBottom: '0.4rem', paddingX: { xs: '1.6rem', sm: '0' } } : { paddingX: { xs: '1.6rem', sm: '0' }, paddingY: '1.6rem', }}
      >
        <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
          {deathBenefitOptedIn ? `Credit Life cover` : `Total Credit Life premiums*`}
        </Typography>

        <Stack flexDirection={'column'} alignItems={'flex-end'} justifyContent={'center'}>
          <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
            {deathBenefitOptedIn ? 'No charge' : `R ${formatMoney(getQuotationResponse?.insuranceFee ?? 0)}`}
          </Typography>
          {!!creditLifeSaving &&
            <Typography fontSize={'1.2rem'} fontWeight={300} lineHeight={'2.2rem'} fontStyle={'italic'} color={'#7AC206'}>
              {`You save R ${formatMoneyWithoutRounding(creditLifeSaving ?? 0, 0)}!`}
            </Typography>
          }
        </Stack>

      </BreakdownItem>

      <BreakdownItem
        flexDirection={'row'}
        alignItems="flex-start"
        justifyContent={'space-between'}
        sx={{ paddingY: '1.6rem', paddingX: { xs: '1.6rem', sm: '0' } }}
      >
        <Stack flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
          <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
            Start date
          </Typography>
        </Stack>

        <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
          Upon loan cash payout
        </Typography>
      </BreakdownItem>

      <BreakdownItem
        flexDirection={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        sx={{ paddingY: '1.6rem', paddingX: { xs: '1.6rem', sm: '0' }, borderBottom: 'none' }}
      >
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
          <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>
            Instalment amount
          </Typography>

          <Box position={'relative'} display={'inline-block'}>
            <Avatar
              src={InfoIcon}
              alt="Information icon"
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                marginLeft: '0.8rem',
                cursor: 'pointer',
              }}
              onClick={() => setShowQuotationBreakdownPopup(true)}
            ></Avatar>
          </Box>
        </Stack>

        <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>
          R {getInstalmentAmount()}
        </Typography>

        <QuotationFeeBreakdownPopup
          open={showQuotationBreakdownPopup}
          handleClose={() => setShowQuotationBreakdownPopup(false)}
          deathBenefitOptedIn={deathBenefitOptedIn}
          getQuotationResponse={getQuotationResponse}
        />
      </BreakdownItem>

      {!deathBenefitOptedIn &&
        <Typography fontSize={{ xs: '1.2rem', md: '1.3rem' }} fontWeight={300} fontStyle={'italic'}>
          *Credit Life is{' '}
          <Typography component={'span'} fontSize={{ xs: '1.2rem', md: '1.3rem' }} fontStyle={'italic'} fontWeight={400} color={theme.palette.primary.main}>
            charged in all loans{' '}
          </Typography>
          which covers the outstanding debt in an unfortunate event.
        </Typography>
      }
    </Container>
  );
};

export default CreditLifeBreakdown;
