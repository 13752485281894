import { PaymentMethodEnums, PaymentStatusEnums } from "../../clients/LoanClient";

export enum PaymentStatus {
    Cancelled = "Cancelled",
    Successful = "Success",
    Failed = "Failed",
}

enum OzowPaymentResponse {
    Cancelled = "cancelledResponse",
    Successful = "successResponse",
    Failed = "errorResponse",
}

//For now this needs to be backwards compatible with the old ozow implementation but eventually we will remove it
export const mapOzowPaymentResponseToPaymentStatus = (ozowPaymentResponse?: string, paymentResponse?: string): PaymentStatus | undefined => {
    if (ozowPaymentResponse === OzowPaymentResponse.Successful) return PaymentStatus.Successful;
    if (ozowPaymentResponse === OzowPaymentResponse.Cancelled) return PaymentStatus.Cancelled;
    if (ozowPaymentResponse === OzowPaymentResponse.Failed) return PaymentStatus.Failed;
    if (paymentResponse === PaymentStatusEnums.Success) return PaymentStatus.Successful;
    if (paymentResponse === PaymentStatusEnums.Cancelled) return PaymentStatus.Cancelled;
    if (paymentResponse === PaymentStatusEnums.Failed) return PaymentStatus.Failed;
    return undefined;
}

export const mapPaymentStatusToPaymentStatusEnum = (paymentResponse?: PaymentStatus): PaymentStatusEnums | undefined => {
    if (paymentResponse === PaymentStatus.Successful) return PaymentStatusEnums.Success;
    if (paymentResponse === PaymentStatus.Cancelled) return PaymentStatusEnums.Cancelled;
    if (paymentResponse === PaymentStatus.Failed) return PaymentStatusEnums.Failed;
    return undefined;
}

export const mapPaymentMethodToPaymentMethodEnum = (paymentMethod?: string): PaymentMethodEnums | undefined => {
    if (paymentMethod === PaymentMethodEnums.Ozow) return PaymentMethodEnums.Ozow;
    if (paymentMethod === PaymentMethodEnums.Card) return PaymentMethodEnums.Card;
    if (paymentMethod === PaymentMethodEnums.CapitecPay) return PaymentMethodEnums.CapitecPay;
    return undefined;
}