import React, { useCallback, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { mapOzowPaymentResponseToPaymentStatus, mapPaymentMethodToPaymentMethodEnum, mapPaymentStatusToPaymentStatusEnum } from "../utils/Helpers/PaymentStatusMappings";
import PaymentStatusDialog from "../components/PaymentStatusDialog/PaymentStatusDialog";
import { useParams, useSearchParams } from "react-router-dom";
import useLoanClient from "../hooks/loan/Client";
import { OzowPaymentStatusRequest, PaymentStatusRequest } from "../clients/LoanClient";

export const PaymentDialogContext = React.createContext({
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
});

export const usePaymentDialog = () => {
    return useContext(PaymentDialogContext);
};

interface paymentDialogProviderProps {
    children: React.ReactNode
}

const PaymentDialogProvider = (props: paymentDialogProviderProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ozowResponse = searchParams.get("ozowResponse");
    const paymentResponse = searchParams.get("paymentResponse");
    const paymentMethod = searchParams.get("paymentMethod");
    const paymentStatus = mapOzowPaymentResponseToPaymentStatus(ozowResponse ?? undefined, paymentResponse ?? undefined);
    const loanClient = useLoanClient();
    const { loanId } = useParams();

    const logOzowStatus = useCallback(async () => {
        if (ozowResponse && loanId) {
            const request = new OzowPaymentStatusRequest({ loanId, status: ozowResponse });
            await loanClient.logPaymentResult(request);
        } else if (paymentResponse && paymentMethod && loanId) {
            const paymentStatusEnum = mapPaymentStatusToPaymentStatusEnum(paymentStatus);
            const paymentmethodEnum = mapPaymentMethodToPaymentMethodEnum(paymentMethod);
            const request = new PaymentStatusRequest({ loanId, status: paymentStatusEnum, paymentMethod: paymentmethodEnum });
            await loanClient.logPaymentMethodResult(request);
        }
    }, [loanClient, ozowResponse, paymentResponse, paymentMethod, paymentStatus, loanId]);

    useEffect(() => {
        logOzowStatus();
    }, [logOzowStatus, ozowResponse]);

    const removeParamsFromUrl = () => {
        setSearchParams(undefined);
    };

    const content = (
        <>
            {!!paymentStatus &&
                <PaymentStatusDialog status={paymentStatus} onButtonClick={removeParamsFromUrl} />
            }
        </>
    );

    return (
        <>
            <PaymentDialogContext.Provider value={{}}>
                {props.children}
            </PaymentDialogContext.Provider>
            {ReactDOM.createPortal(
                content,
                /* eslint-disable  @typescript-eslint/no-non-null-assertion*/
                document.getElementById("payment-dialog-root")!
            )}
        </>
    );
};

export default PaymentDialogProvider;
