import { Box, Dialog, Stack, Typography } from "@mui/material";
import styles from "./PaymentStatusDialog.module.scss";
import PaymentSuccessIcon from "../../assets/img/svg/PaymentSuccess.svg";
import PaymentFailedIcon from "../../assets/img/svg/PaymentFailed.svg";
import { PaymentStatus } from "../../utils/Helpers/PaymentStatusMappings";
import PrimaryButton from "../Buttons/PrimaryButton";
import TextButton from "../Buttons/TextButton";

interface PaymentStatusDialogProps {
    onButtonClick: () => void;
    status: PaymentStatus;
}

const PaymentStatusDialog = ({ onButtonClick, status }: PaymentStatusDialogProps) => {
    const isPaymentSuccessful = status === PaymentStatus.Successful;
    const isPaymentCancelled = status === PaymentStatus.Cancelled;

    const title = () => {
        if (isPaymentSuccessful) return <Typography fontSize={'2.4rem'} lineHeight={'3rem'} fontWeight={500}>Payment confirmed</Typography>;
        if (isPaymentCancelled) return <Typography fontSize={'2.4rem'} lineHeight={'3rem'} fontWeight={500}>Payment cancelled</Typography>;
        return <Typography fontSize={'2.4rem'} lineHeight={'3rem'} fontWeight={500}>Payment failed</Typography>;
    };

    const subtitle = () => {
        if (isPaymentSuccessful) {
            return <Typography fontSize={'1.5rem'} lineHeight={'2rem'} fontWeight={300}>
                Please be patient while we update your Wonga account balance. Updates may take up to
                <Typography fontWeight={500} component={'span'}>
                    {" "}48 hours to process.
                </Typography>
            </Typography>;
        }
        if (isPaymentCancelled) {
            return <Typography fontSize={'1.5rem'} lineHeight={'2rem'} fontWeight={300}>
                Unfortunately we were not able to process your payment.
            </Typography>;
        }
        return <Typography fontSize={'1.5rem'} lineHeight={'2rem'} fontWeight={300}>
            Unfortunately we were not able to process your payment. Please ensure you have sufficient funds.
        </Typography>;
    };

    const iconSrc = () => {
        if (isPaymentSuccessful) return PaymentSuccessIcon
        return PaymentFailedIcon;
    };

    const buttonText = () => {
        if (isPaymentSuccessful) return "Okay";
        if (isPaymentCancelled) return "Try again";
        return "Retry different method";
    };

    return (
        <Dialog
            open
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "50rem",
                        borderRadius: '0.8rem'
                    },
                },
            }}>
            <Stack padding={'3.2rem 2rem'} justifyContent={'center'} alignItems={'center'} spacing={'2.2rem'}>
                <img
                    src={iconSrc()}
                    aria-label="Payment Icon"
                    className={styles["payment-status-icon"]}
                />
                {title()}
                <Box textAlign={'left'} width={'100%'}>
                    {subtitle()}
                </Box>
                <Stack width={"100%"} alignItems={'center'} spacing={'1.4rem'}>
                    <PrimaryButton fullWidth onClick={onButtonClick}>
                        {buttonText()}
                    </PrimaryButton>
                    {
                        !isPaymentSuccessful &&
                        <TextButton onClick={onButtonClick}>
                            Cancel
                        </TextButton>
                    }
                </Stack>
            </Stack>
        </Dialog>
    );
};

export default PaymentStatusDialog;