import { Stack, Avatar, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import chevronLeft from '../../assets/img/svg/Chevron.svg';
import { theme } from "../../theme/Theme";

type NavigateChevronLinkProps = {
    route: string;
    children: React.ReactNode;
}

const NavigateChevronLink: React.FunctionComponent<NavigateChevronLinkProps> = (props) => {
    // This is the breakpoint for the sideNav
    const isSideNavHidden = useMediaQuery(theme.breakpoints.down(960));

    return (
        <Link to={props.route} style={{ display: 'block', textDecoration: 'none', color: 'inherit', width: '12rem', marginTop: isSideNavHidden ? '0' : '-4rem' }}>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} marginBottom={{ xs: '2rem', md: '2.4rem' }}>
                <Avatar
                    src={chevronLeft}
                    alt="Back button icon"
                    sx={{
                        width: '1.4rem',
                        height: '1.4rem',
                        marginRight: '0.4rem',
                    }}
                />
                <Typography
                    display={'inline'}
                    fontSize={'1.5rem'}
                    fontWeight={'300'}
                    lineHeight={{ xs: '2.2rem' }}
                    letterSpacing={{ xs: '-.0.04rem' }}
                >
                    {props.children}
                </Typography>
            </Stack>
        </Link>
    );
}

export default NavigateChevronLink;