/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import React, { useEffect, useState } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './InstantEft.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse, PaymentMethodEnums, PaymentRedirectUrlResponse, SessionUrlResponse } from '../../../../../clients/LoanClient';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { Form, useForm } from '../../../../../components/Form/Form';
import { repaySchema } from '../../../../../schemas/Schemas';
import useLoanClient from '../../../../../hooks/loan/Client';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import Loading from '../../../../../components/Loading/Loading';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';
import { RouterRoutes } from '../../../../../utils/RouterRoutes';
import NavigateChevronLink from '../../../../../components/NavigateChevronLink/NavigateChevronLink';
import TextButton from '../../../../../components/Buttons/TextButton';

interface InstantEftProps {
  openLoanResponse: IOpenLoanDetailsResponse;
}

const InstantEft: React.FunctionComponent<InstantEftProps> = ({ openLoanResponse }: InstantEftProps) => {
  const { currentBalance, collectionDate, collectionInstalment } = openLoanResponse.loanData || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const [isLoading, setLoading] = useState(false);
  const { repayOptions } = useAppSettings();
  const { loanId } = useParams();
  const navigate = useNavigate();


  const form = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: repaySchema(currentBalance ?? 0, repayOptions.minimumRepayAmountInRands ?? 75),
  });

  useEffect(() => {
    form.setValue('repayAmount', formatMoney(currentBalance ?? 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable  @typescript-eslint/no-explicit-any*/
  const handlePaymentSession = async (data: any) => {
    try {
      setLoading(true);
      let ozowResponse: PaymentRedirectUrlResponse | SessionUrlResponse;
      // This is only here for the time being incase we have issues with the initial precium integration in prod.
      // TODO: This should be removed once we have fully cut over to using precium for payments
      if (repayOptions.preciumEnabled) {
        ozowResponse = await client.getPaymentRedirectUrl(loanId ?? '', data.repayAmount, PaymentMethodEnums.Ozow);
      } else {
        ozowResponse = await client.getPaymentSessionUrl(loanId ?? '', data.repayAmount);
      }
      if (ozowResponse?.sessionUrl) {
        window.location.replace(ozowResponse.sessionUrl);
      } else {
        displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
        setLoading(false);
      }
    } catch (error) {
      displaySnackBar('We are currently experiencing technical difficulties. Please try again later', 'error');
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate(`/${RouterRoutes.myLoan}/${loanId}/${RouterRoutes.repay}`);
  };

  return (
    <div className={styles['instant-eft-container']}>
      {
        !isMobile &&
      <NavigateChevronLink route={`/${RouterRoutes.myLoan}/${loanId}/${RouterRoutes.repay}`}>Back</NavigateChevronLink>
      }
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
      >
        Repay using Instant EFT Ozow
      </Typography>
      <div className={styles['instant-eft-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['instant-eft-hint-icon']} />
        <Typography fontWeight={300} fontSize={'1.5rem'} component={'span'}>
          Payments made within
          <Typography fontWeight={500} component={'span'}>
            {" "}5 business days{" "}
          </Typography>
          of your next debit order collection
          <Typography fontWeight={500} component={'span'}>
            {" "}may not be processed in time.{" "}
          </Typography>
          Once payment is made, this can take
          <Typography fontWeight={500} component={'span'}>
            {" "}2-3 business days{" "}
          </Typography>
          to reflect on your loan account.
        </Typography>
      </div>

      <Typography
        variant="body1"
        fontSize={'3.2rem'}
        fontWeight={600}
        lineHeight={'2.2rem'}
        letterSpacing={'-0.04em'}
        textAlign={'center'}
        marginTop={isMobile ? '2.4rem' : '3.2rem'}
        color={theme.palette.primary.dark}
      >
        R {formatMoney(currentBalance ?? 0)}
      </Typography>
      <Stack
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginTop={'1.1rem'}
        marginBottom={'2rem'}
      >
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
          Settlement balance
        </Typography>
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
          {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
        </Typography>
      </Stack>


      <div className={styles['instant-eft-table-first-row']}>
        <div>New debit collection date</div>
        <div>{formatDateWithDefault(collectionDate, 'do MMM yyyy')}</div>
      </div>
      <div className={styles['instant-eft-table']}>
        <div>Your next debit amount</div>
        <div>R {formatMoney(collectionInstalment ?? 0)}</div>
      </div>

      <Form form={form} onSubmit={(data) => handlePaymentSession(data)}>
        <Stack direction={{ xs: 'column', sm: 'row' }} paddingTop={'3.6rem'} spacing={{ xs: '1.2rem', sm: '6.6rem' }} alignItems={'flex-start'} >
          <Typography fontWeight={300} whiteSpace={'nowrap'} paddingTop={{ xs: '0', sm: '1.5rem' }}>
            How much do you want to repay?
          </Typography>
          <InputFormController
            currencyField
            name="repayAmount"
            label="repayAmount"
            placeholder="Amount"
            register={form.register}
            control={form.control}
          />
        </Stack>
        {isLoading ? (
          <Loading />
        ) : (
          <Stack
            direction={isMobile ? 'column' : 'row-reverse'}
            justify-content={isMobile ? 'center' : 'right'}
              padding="3rem 0rem 2rem 0rem"
              spacing={2}
          >
              <PrimaryButton type="submit">Confirm</PrimaryButton>
              {
                isMobile &&
                <TextButton onClick={goBack}>Back</TextButton>
              }
          </Stack>
        )}
      </Form>
    </div>
  );
};

export default InstantEft;
