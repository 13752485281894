import React from 'react';
import { formatMoney } from '../../utils/Helpers/SliderHelperFunctions';
import { Stack, Typography } from '@mui/material';
import InfoIcon from '../../assets/img/info-icon.png';
import { theme } from '../../theme/Theme';

interface LoanDetailsProps {
  loanAmount: number;
  totalInterestAndFees: number;
  isInstalmentLoan: boolean;
  term: number;
  onShowFeesBreakdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoanDetails: React.FunctionComponent<LoanDetailsProps> = (props: LoanDetailsProps) => {
  const { loanAmount, totalInterestAndFees, isInstalmentLoan, term, onShowFeesBreakdown } = props;
  const greyBorder = `1px solid ${theme.palette.grey[200]}`;

  const calculateIlOrSTL = () => {
    if (isInstalmentLoan) {
      return <>{term} Months</>;
    } else {
      return <>{term} Days</>;
    }
  };

  return (
    <>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
        marginBottom={'2rem'}
      >
        Loan application details
      </Typography>
      <TableRow>
        <div>Loan Amount</div>
        <Typography fontWeight={600}>R {formatMoney(loanAmount)}</Typography>
      </TableRow>
      <TableRow borderTop={greyBorder}>
        <Stack direction={'row'} alignItems={'center'}>
          Total fees & interest
          <img
            onClick={() => onShowFeesBreakdown(true)}
            src={InfoIcon}
            width={'15px'}
            height={'15px'}
            style={{ marginLeft: '0.8rem', cursor: 'pointer' }}
            alt="infoIcon"
          />
        </Stack>
        <Typography fontWeight={600}>R {formatMoney(totalInterestAndFees)}</Typography>
      </TableRow>
      <TableRow borderTop={greyBorder} borderBottom={greyBorder}>
        <div> Term</div>
        <Typography fontWeight={600}>{calculateIlOrSTL()}</Typography>
      </TableRow >
      <br />
    </>
  );
};

type TableRowProps = {
  children: React.ReactNode;
  borderTop?: string | undefined;
  borderBottom?: string | undefined;
}

const TableRow: React.FC<TableRowProps> = ({ children, borderTop, borderBottom }) => {
  return <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{
    width: '100%',
    borderTop: borderTop ?? 'none',
    borderBottom: borderBottom ?? 'none',
    height: '5rem',
    fontSize: '1.5rem',
    fontWeight: '300',
    fontStyle: 'normal',
  }}>
    {children}
  </Stack>
}

export default LoanDetails;
